.video-list{
    padding-left:0;
    list-style-type: none;
}
.single-video{
    margin-bottom:10px;
}
.video iframe{
    width:100%;
    height:auto;
    min-height: 189px;
}
.video img{
    width:100%;
    max-height:300px;
    object-fit:cover;
    cursor: pointer;
}
.video-info{
    padding:15px;
}
.video-info img{
    width: 36px;
    height: 36px;
    position:absolute;
}
.video-desc{
    display:block;
    padding-left:50px;
}
.video-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #0A0A0A;
    margin-bottom:8px;
}
.video-meta{    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6C6C6C;
}
.video-source {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-left: 0;
    padding-left: 0;
    gap:2px;
    max-width:250px;
    margin:0 auto;
}

.video-source li {
    width: 100%;
    list-style-type: none;
    background: #FFFFFF;
    border: 0.5px solid #D23331;
    border-radius: 7px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 3px 10px;
    color: #D23331;
    cursor: pointer;
    display:block;
}

.video-source li.active {
    color: #FFFFFF;
    background: #D23331;
}