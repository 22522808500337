header#dashboard-header {
    background:none;
}
#dashboard-content.content{    
    /* background: #1F2E4D !important; */
    background: #151c46 !important;
}
.dashboard-wrap{
    color: #ffffff;
    display:flex;
    flex-direction: column;
    min-height: calc(100vh - 160px);
    justify-content: space-between;
}
/* .options{
    justify-content: flex-end;
} */
.options ul{
    padding-left:0; 
    display:flex;    
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    gap:10px;
    margin-bottom:10px;
}
.options li{
    list-style-type: none;
    width:30%;
    display:block;
    text-align:center;
    background: #283859;
    border-radius:5px;
    padding:10px 0;
    min-height:70px;
    flex-grow: 1;
    position: relative;
}
.options li svg{
    width:25px;
    height:25px;
}
.options li svg path{
    fill: #fff;
}
.options li svg.svg-ico-user path{
    fill: none !Important;
    stroke: #fff;
}
.options li a{
    cursor: pointer;
    display:inline-block;
    color: #fff;
    text-decoration:none;
}
.options li a:active svg path{
    fill: #736D6D;
}
.options li a:active svg.svg-ico-user path{
    stroke: #736D6D;
}
.options li a:active .title{
    color: #736D6D;
}
.options li .title{
    display:block;
    font-size:12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-top:5px;
}
.options .alert-count{
    position: absolute;
    top:5px;
    right:8px;
    background:#D23331;
    padding:0px 5px !important;
    line-height:1.3;
    border-radius:100%;
    font-size:15px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    color: #fff;
    min-width:20px;
}