header {
    width: 100%;
    background: #ffffff;
    height: 45px;
    max-height:45px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display:flex;
    align-items: center;
}
.go-back{
    display:block;
    text-align:center;
    width:32px;
    height:32px;    
    border-radius:20px;
    background: #1E2E50;
    border:1px solid #1E2E50;
    line-height:1.7;
    margin-left:15px;
    cursor:pointer;
}
.go-back svg {
    width:11px;
    height:11px;
}
.go-back svg path{
    fill:#ffffff;
}
.content {
    padding-top: calc(22px + 45px);
    padding-bottom: 30px;
    background: #ffffff !important;
    /* min-height: calc(100vh - 30px); */
    min-height: 100vh;
}

#external-content{
    padding-bottom:150px;
}

.content.with-footer-nav {
    padding-bottom: 110px;
}

footer {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #111826;
    z-index:100;
}


.page-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
}

.page-time{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #595959;
    text-align:center;
    margin-bottom:20px;
}

.search-wrap {
    position: relative;
    margin-bottom: 21px;
}

.search-wrap input[type=text] {
    box-sizing: border-box;
    width: 100%;
    height: 51px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    padding-left: 40px;
}

/* .search-wrap .css-1s2u09g-control{
    box-sizing: border-box;
    width: 100%;
    height: 51px;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    padding-left: 30px;
}
.search-wrap .css-1pahdxg-control{    
    padding-left: 30px;
}
.search-wrap .css-319lph-ValueContainer{
    height:51px;
}

.search-wrap .css-1hb7zxy-IndicatorsContainer, .search-wrap .css-tj5bde-Svg{
    display:none;
} */

.search-wrap svg {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 1;
}

.contact-category {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-left: 0;
    padding-left: 0;
    gap:2px;
}

.contact-category li {
    width: 100%;
    list-style-type: none;
    background: #FFFFFF;
    border: 0.5px solid #D23331;
    border-radius: 7px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 3px 10px;
    color: #D23331;
    cursor: pointer;
    display:block;
}

.contact-category li.active {
    color: #FFFFFF;
    background: #D23331;
}

.search-wrap .result-count {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #A9A9A9;
    text-align: right;
}

.btn-action-wrap{
    display:block;
    margin-bottom:20px;
    text-align:right;
}

.btn-action-wrap button{
    font-size:12px;
}

.item-list li {
    list-style-type: none;
    box-sizing: border-box;
    background: #F4F4F4;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 15px 20px 15px 40px;
    margin-bottom: 11px;
    font-family: 'Roboto', sans-serif;
    position: relative;
}

.item-list.list-alert{
    padding-bottom:80px;
}

.item-list.list-alert li{
    cursor:pointer;
}

.item-list .item-icon {
    position: absolute;
    left: 10px;
}

.item-list .item-icon svg path {
    fill: #BDBDBD;
}

.item-list .item-icon.highlight svg path {
    fill: #DD5762;
}

.item-list .item-header {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #181818;
}

.item-list.list-contact .item-header {
    color: #595959;
}

.item-list.list-alert .item-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.item-list .item-header a {
    text-decoration: none;
    color: #181818;
}

.item-list .item-header.highlight {
    color: #D23331;
}

.item-list .item-header.highlight a {
    color: #D23331;
}

.item-list .item-header .post-time{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #595959;
    margin-left:8px;
}

.item-list .item-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #595959;
}

.item-list.list-alert .item-desc{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.item-list.list-alert .item-desc.highlight{
    font-weight:600;
}

.item-list .item-desc a {
    text-decoration: underline;
}

.item-list .item-desc label {
    display: block;
    padding-left: 15px;
    position: relative;
}

.item-list .item-desc label svg {
    position: absolute;
    left: 0;
    top: 3px;
}

.popup-form .btn-primary {
    width: 100%;
}
.popup-form  .close-popup{
    color: #000;
    text-decoration:none;
    text-align:center;
    display:block;
    margin-bottom:20px;
    cursor:pointer;
}
.popup-form.was-validated .form-select:valid:not([multiple]):not([size]){
    background-repeat:no-repeat;
}
.popup-form input[type=text],
.popup-form input[type=email],
.popup-form textarea{
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #8F9BB3 !important;
}
.popup-form input[type=text]::placeholder ,
.popup-form input[type=email]::placeholder ,
.popup-form textarea::placeholder {
    color: #8F9BB3 !important;
}