@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Montserrat:wght@700&family=Mulish:wght@600&family=Open+Sans:wght@600;700&family=Poppins:wght@400;600&family=Roboto:ital,wght@0,500;0,700;1,400&display=swap');

@font-face {
  font-family: 'SF UI Text';
  src: url('../src/fonts/SFUIText-Medium.woff2') format('woff2'),
    url('../src/fonts/SFUIText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/*
    font-family: 'Inter', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Mulish', sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
*/

body {
  /* background: #1F2E4D !important; */
  font-family: 'Poppins', sans-serif !important;
}

body a {
  color: #D23331;
}

body {
  touch-action: pan-y;
}

iframe {
  height: calc(100vh - 210px);
}

.LazyLoad {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.svg-ico {}

.svg-ico-25 {
  width: 25px !important;
  height: 25px !important;
}

.svg-ico-16 {
  width: 16px !important;
  height: 16px !important;
}

.svg-ico-18 {
  width: 18px !important;
  height: 18px !important;
}

.btn {
  box-shadow: none !important;
  border: none !important;
}

.btn-primary {
  background: #D23331 !important;
  padding: 15px 50px !important;
  width: 243px;
  height: 54px;
  font-size: 16px;
  border-radius: 10px !important;
  color: #fff;
}

.btn-primary:active {
  background: #fff !important;
  color: #000 !important;
}

.btn-green{
  background: rgb(79, 157, 62) !important;
  color: #fff !important;
}

button svg{
  width:16px;
  height:16px;
}

button svg path, .btn svg path{
  fill:#ffffff;
}

#main {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.content {
  /* display: inline-block; */
}

.container {
  max-width: 560px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  
  position: relative;
  
}

.Toastify .Toastify__toast-container--top-center{
  margin-top:20px !important;
}

._loading_overlay_wrapper--active,
.react-confirm-alert-overlay {
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  z-index: 1000 !important;
  top: 0 !important;
  left: 0 !important;
}

.react-confirm-alert-overlay{
  background: rgba(0,0,0,0.7) !important;
}

.react-confirm-alert-body{
  max-width:100%;
  width:auto !important;
  min-width:300px;
}
.react-confirm-alert{
  max-width:80%;
}

.alert {
  font-size: 14px;
  padding: 10px 20px;
}

.alert.danger {
  color: #D23331;
  background-color: #fff;
  border-color: #D23331;
}

.alert .btn-close{
  position:absolute;
  display:block;
  top: 6px;
  right: 4px;
  font-size:10px;
}

.popup-overlay {
  background: rgba(255, 255, 255, 0.9) !important;
  z-index: 99 !important;
  overflow: auto;
}
@keyframes taadaa { 
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.popup-content {
  width: 95% !important;
  background: #FFFFFF;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.160784);
  border-radius: 32px;
  padding: 20px 20px 140px 20px !important;
  bottom: 0 !important;
  
  animation: taadaa 1s;
}
.popup-content .close-popup.btn-close {
  position:absolute;
  right:15px;
  top:15px;
}

body .form-control, body .form-select {
  box-sizing: border-box;
  border: 1px solid #EDF1F7;
  border-radius: 8px !important;
  min-height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #8F9BB3;
  line-height:2.4;
}

body .form-text{
  font-size:11px;
  font-style: italic;
  color: #8F9BB3;
  line-height:1.5;
  display: inline-block;
  padding:0 10px;
}

body .form-text.text-larger{
  font-size: 12px;
}

body .form-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}

.react-time-picker__clock-button svg path {
  stroke: #8F9BB3 !important;
}

.form-wrap h3 {
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 20px;
}

.react-time-picker__wrapper {
  border: none !important;
}

body .MuiFormControl-root {
  width: 100%;
}

body .MuiFormControl-root .MuiOutlinedInput-root {
  height: 50px;
}

body .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  box-sizing: border-box;
  border: 1px solid #EDF1F7;
  border-radius: 8px !important;
}

body .MuiFormControl-root input {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #8F9BB3;
}

body .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #EDF1F7;
}

body .MuiInputAdornment-root svg path {
  fill: #8F9BB3;
}

body .MuiInputLabel-formControl {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #8F9BB3;
}
.view-gallery-overlay{
  z-index: 1000 !important;
}
.view-gallery-content{
  padding: 50px 20px 50px 20px !important;
  /* margin: 10px 0 !important; */
  /* bottom:0 !important; */
}
.view-gallery-content video{
  max-height:55vh;
}
.owl-theme .owl-nav{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  display:inline;
  top:calc(50% - 62px);
}
.owl-carousel .owl-nav button.owl-prev{
  position:absolute;
  left:0;
  z-index:1;
}
.owl-carousel .owl-nav button.owl-next{
  position:absolute;
  right:0;
  z-index:1;
}
.owl-theme .owl-nav [class*='owl-']{
  font-size:60px !important;
}
.howler-player{
  background: rgb(240, 240, 240);
  border-radius:50px;
  margin:8px auto;
  display:block;
  position:relative;
  padding:13px 20px;
  max-width: 300px;
}
.howler-filename{
  padding-right:50px;
  text-align:left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.howler-control{
  position:absolute;
  right:4px;
  top:4px;
  background:rgb(107, 107, 107);
  border-radius:100px;
  width:42px;
  height:42px;
  border:none;
  text-align: center;
}
.howler-control svg{
  width:20px;
  height:20px;
  display:inline-block;
  text-align:center;
}
.howler-control svg path{
  fill:#ffffff;
}