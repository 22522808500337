.list-contact{
    margin-bottom:50px;
}
.list-contact .position{
    font-size:12px;
    color: #999;
    font-style: italic;
    font-weight: 500;
    margin-left:3px;
}
.list-contact a{
    color: #595959;
    text-decoration: none !important;
    cursor: pointer !important;
    display: block;
}
.list-contact a:hover{
    color: #DD5762 !important;
}