#single-alert-content{
    padding-bottom:180px;
}
#single-alert-content .page-title{
    margin-bottom:10px;
    text-transform: capitalize;
}
.alert-detail{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.alert-detail img{
    display:block;
    width:100%;
    height:auto;
    margin-bottom:22px;
}
.alert-link a{
    display:block;
    margin-top:11px;    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #D23331;
    text-transform: uppercase;
    text-decoration: none;
}