.profile-setting{
    padding-bottom:80px;
}
.form-profile{
    
}
.form-profile .form-label{
    padding-left:14px;
    font-size:14px;
}
.form-profile .form-switch{
    display:inline-block;
}
.form-profile input[type="checkbox"]{
    font-size:24px;
    position: relative;
    top:-6px;
    display: inline-block;
    text-align: left;
    margin-left: 0;
}
.form-profile .btn-primary{
    width:100%;
}
.form-profile .css-1s2u09g-control, .form-profile .css-1pahdxg-control{
    background: #FFFFFF;
    /* border: 1px solid #C8C8C8; */
    border: 1px solid #EDF1F7;
    box-shadow: 0px 0px 16px rgba(234, 76, 137, 0.06);
    border-radius: 6px;
    /* max-width: 250px; */
    min-height: 50px;
    margin-left: auto;
    margin-right: auto;
    color: #8F9BB3;
}
.form-profile .css-1okebmr-indicatorSeparator{
    background-color: #EDF1F7;
}
.form-profile .css-qc6sy-singleValue{
    color: #8F9BB3;
    font-size:15px;
}
.form-profile .css-26l3qy-menu{
    z-index:1000;
}
.form-profile .css-1rhbuit-multiValue{
    border-radius:5px;
}
.form-profile .css-26l3qy-menu input[type="checkbox"]{
    position:relative;
    top:-1px;
    margin-right:10px;
}
.form-profile .css-26l3qy-menu .option > div{
    padding: 10px;
    height: 50px;
    color: #7B7B7B;
}
.form-profile .css-26l3qy-menu .platoon-a > div{    
    background: rgba(221, 87, 98, 0.15);
}
.form-profile .css-26l3qy-menu .platoon-b > div{
    background: rgba(76, 116, 162, 0.15);
}
.form-profile .css-26l3qy-menu .platoon-c > div{
    background: rgba(21, 162, 133, 0.15);
}
.form-profile .css-26l3qy-menu .platoon-d > div{
    background: rgba(242, 222, 78, 0.15);
}
.form-profile .css-26l3qy-menu .day > div{
    background: rgba(136, 108, 157, 0.15);
}
.g-sign-in-button {
    /* margin: 10px; */
    display: inline-block;
    width: 240px;
    height: 50px;
    background-color: #4285f4;
    color: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.g-sign-in-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-sign-in-button:active {
    background-color: #3367D6;
    transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
}

.g-sign-in-button img {
    width: 18px;
    height: 18px;
}

.g-sign-in-button .logo-wrapper {
    padding: 15px;
    background: #fff;
    width: 48px;
    height: 100%;
    border-radius: 1px;
    display: inline-block;
    line-height: 1;
}

.g-sign-in-button .text-container {
    font-family: Roboto,arial,sans-serif;
    font-weight: 500;
    letter-spacing: .21px;
    font-size: 16px;
    line-height: 48px;
    vertical-align: top;
    border: none;
    display: inline-block;
    text-align: center;
    width: 180px;
}