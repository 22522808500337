
.add-event-category{
    position:fixed;
    bottom:112px;
    background: #fff;
    left:0;
    box-shadow:  0px -8px 24px rgba(0, 0, 0, 0.15);
    border-radius:10px;
    padding:10px;
    z-index:10;
}
.add-event-category h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.event-category-select li{    
    list-style-type:none;
    display:inline-block;
    margin-right:10px;
    margin-bottom:15px;
}
.event-category-select li a{    
    border-radius: 11px;
    background: rgba(100,100,100,0.3);
    mix-blend-mode: normal;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    padding:5px 20px 5px 10px;
    cursor:pointer;
    text-decoration:none;
}
.event-category-select .circle{
    display:inline-block;
    width:10px;
    height:10px;
    background: transparent;
    border-radius:10px;
    border:3px solid #000;
    margin-right:10px;
}
.event-category-select .note{
    background: rgba(115, 91, 242, 0.1);
}
.event-category-select .note .circle{
    border-color: rgba(115, 91, 242, 1);
}
.event-category-select li:active .note{
    background: rgba(115, 91, 242, 1);
    color: #fff;
}
.event-category-select li:active .note .circle{
    border-color: rgba(255, 255, 255, 1);
}
.event-category-select .event{
    background: rgba(0, 179, 131, 0.1);
}
.event-category-select .event .circle{
    border-color: rgba(0, 179, 131, 1);
}
.event-category-select li:active .event{
    background: rgba(0, 179, 131, 1);
    color: #fff;
}
.event-category-select li:active .event .circle{
    border-color: rgba(255, 255, 255, 1);
}
.event-category-select .annual{
    background: rgba(255, 122, 0, 0.1)
}
.event-category-select .annual .circle{
    border-color: rgba(255, 122, 0, 1)
}
.event-category-select li:active .annual{
    background: rgba(255, 122, 0, 1);
    color: #fff;
}
.event-category-select li:active .annual .circle{
    border-color: rgba(255, 255, 255, 1);
}
.event-category-select .dayoff{
    background: rgba(0, 149, 255, 0.1);
}
.event-category-select .dayoff .circle{
    border-color: rgba(0, 149, 255, 1);
}
.event-category-select li:active .dayoff{
    background: rgba(0, 149, 255, 1);
    color: #fff;
}
.event-category-select li:active .dayoff .circle{
    border-color: rgba(255, 255, 255, 1);
}
.event-category-select .training{
    background: rgba(244, 89, 138, 0.1);
}
.event-category-select .training .circle{
    border-color: rgba(244, 89, 138, 1);
}
.event-category-select li:active .training{
    background: rgba(244, 89, 138, 1);
    color: #fff;
}
.event-category-select li:active .training .circle{
    border-color: rgba(255, 255, 255, 1); 
}
.event-category-select .ot{
    background: rgba(255, 199, 0, 0.1);
}
.event-category-select .ot .circle{
    border-color: rgba(255, 199, 0, 1);
}
.event-category-select li:active .ot{
    background: rgba(255, 199, 0, 1);
    color: #fff;
}
.event-category-select li:active .ot .circle{
    border-color: rgba(255, 255, 255, 1); 
}
.form-add-event #event_recurring {
    float: right !important;
    font-size: 25px;
}
.popup-wrap{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.7);
    overflow:auto;
    padding:30px 0 120px 0;
    display:flex;
    align-items: center;
    z-index: 1;
}
.recurrence-popup{
    padding:10px;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    border-radius: 5px;
    background-color: #fff;
    width:90%;
    margin:auto auto;
}
.recurrence-popup h4{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color:#000000;
    margin: 10px 0 30px;
}
.recurrence-popup label{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color:#000000;
    display:block !important;
    margin-right:20px;
    margin-bottom:10px;
}
.recurrence-popup input[type=text], .recurrence-popup select{
    min-height:auto;
    height:40px !important;
    background:#f1f3f4;
    border-radius:5px !important;
    border:0;
    /* padding:10px !important; */
    font-weight: 400;
    font-size: 15px;
    color: #8F9BB3;
    margin:0 10px 0 0;
    display:inline-block;
    line-height:1;
}
.recurrence-popup .MuiOutlinedInput-root{
    color: #8F9BB3;
    margin:0 10px 0 0;
    min-height:auto;
    height:40px !important;
    background:#f1f3f4;
}
.recurrence-popup .input-group{
    display:flex;
    align-items: center;
    margin-bottom:20px;
}
.recurrence-popup .input-group .row{
    width:100%;
    display:flex;
    align-items:center;
    min-height:50px;
}
.recurrence-popup ul{
    display:block;
    width:100%;
    margin-left:0;
    padding-left:0;
    margin-top:10px;
}
.recurrence-popup li{
    list-style-type:none;
    display:inline-block;
    margin-right:10px;
}
.recurrence-popup li a{
    /* padding:3px 10px; */
    width:30px;
    height:30px;
    display: block;
    text-align: center;
    line-height: 1.8;
    background:#f1f3f4;
    border-radius:20px;
    color:#80868b;
    text-decoration: none;
}
.recurrence-popup ul li a.active{
    background:#1a73e8;
    color: #fff;
}
.recurrence-popup .btn-wrap{
    text-align:right;
    margin-top:30px;
    display:block;
}
.recurrence-popup .btn{
    margin-left:10px;
    background: rgba(217, 217, 217, 0.2);
    color:#5694e7;
    cursor:pointer;
}
.recurrence-popup .btn-cancel{
    color:#000000;
    background:#fff;
}
.note-wrap{
    position:relative;
    display:block;
}
.action-wrap{
    position:absolute;
    z-index: 1;
    bottom: 0;
    right:15px;
}
.action-wrap li{
    display:inline-block;
    margin:0 3px;
    list-style-type: none;
}
.action-wrap li svg{
    cursor: pointer;
    width:32px;
    height:32px;
    padding:5px;
    border-radius:100px;
}
.action-wrap li svg:hover{
    background-color: rgba(0, 0, 0, 0.04);
}
#event_note{
    padding-bottom:50px;
}
.react-html5-camera-photo{
    position:fixed !important;
    z-index:1000 !important;
    top:0;
    left:0;
    padding:0;
    height:calc(100% - 111.5px);
    width:100%;
    display: grid;
    align-items: center;
    place-items: center;
    background: #000;
}
#container-circles{
    bottom: 40px !important;
}
.react-html5-camera-photo #white-flash{
}
.react-html5-camera-photo video{
    z-index: 1;
    position: relative;
    max-height:calc(100vh - 111.71px);
}
.voice-recorder{
    position:fixed;
    top:0;
    left:0;
    z-index: 1000;
    height:calc(100vh - 111.71px);
    overflow: hidden;
    width:100%;
    background: #fff;
    /* padding-top:30px; */
    display: grid;
    align-items: center;
    justify-content: center;
    place-items: center;
    text-align: center;
}
.camera-wrap{
    /* position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0; */
}
.act-close{
    position: fixed;
    top:10px;
    right:10px;
    font-size:50px;
    z-index: 1001;
    width:40px;
    height:40px;
}
.act-close path{
    fill: #ddd;
}
.voice-recorder .audio-recorder{
    display:none !important;
}
.voice-recorder .recording-image{
    /* margin-top:calc(50% + 50px); */
    max-width: 100%;
    overflow: hidden;
}
.voice-recorder .recording-msg{
    display:block;
    font-family: "Inter";
    font-size:14px;
    font-weight:700;
    color:#D23331;
}
.voice-recorder .recording-image svg{
    display:inline-block;
}
.voice-recorder .recording-image svg:nth-child(2){
    position:relative;
    margin-left:-10px;
}
.voice-recorder .audio-react-recorder__canvas{
    margin-top:calc(50% + 70px);
    width: 100%;
    height:70px;
}
.voice-recorder .msg-command{
    font-size:14px;
    font-family:"Inter";
    font-weight:400;
    display:inline-block;
    position:absolute;
    bottom:60px;
    left:0;
    width:100%;
}
.voice-recorder audio{
    margin-bottom:30px;
}
.voice-wrap{
    height:100%;
    overflow: auto;
    padding: 0 30px 30px 30px;
}
.media-thumb {
    margin-left:0;
    padding-left:0;
    display: flex;
    vertical-align: top;    
    flex-wrap: wrap;
}
.media-thumb li {
    display:inline-block;
    /* margin-right:5px; */
    padding:2px;
    width:33.33%;
    position: relative;
}
.media-thumb .btn-remove{
    position: absolute;
    top:2px;
    right:2px;
    z-index:1;
}
.media-thumb .react-thumbnail-generator{
    height:80px;
    width:100%;
    display:inline-block;
    overflow: hidden;
    display:none;
}
@media screen and (max-width:767px){
    .media-thumb li {
        width:33.33%;
    }
}
@media screen and (min-width:768px){
    .media-thumb li {
        width:80px;
    }
}
.media-thumb img{
    width:100%;
    height:80px;
    object-fit: cover;
}