#nav-mobile {
    width: 100%;
    display: block;
    padding-top: 40px;
    padding-bottom: 30px;
}

#nav-mobile ul {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-left: 0;
    padding-left: 0;
}

#nav-mobile li {
    width: 100%;
    list-style-type: none;
    text-align: center;
    position: relative;
}

#nav-mobile li a {
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

#nav-mobile li .svg-ico path {
    fill: #736D6D;
    stroke: none;
}

#nav-mobile li .svg-ico:active path {
    fill: #fff;
}

#nav-mobile li a.active .svg-ico path {
    fill: #ffffff;
}

#nav-mobile .svg-ico-user path {
    fill: none !important;
    stroke: #736D6D !important;
}

#nav-mobile li a.active .svg-ico-user path {
    fill: none !important;
    stroke: #ffffff !important;
}

#nav-mobile li a:active .svg-ico-user path {
    stroke: #ffffff !important;
}

#nav-mobile .alert-count {
    position: absolute;
    margin-top: -10px;
    margin-left: -4px;
    background: #D23331;
    padding: 0px 5px !important;
    line-height: 1.3;
    border-radius: 100%;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    color: #fff;
    min-width:20px;
}
.fixed-add-wrap{
    width:100%;
    text-align:center;
    position:fixed;
    bottom:90px;
    z-index: 101;
    left:0;
}
.button{
    background: #D23331;
    border-radius:50px;
    width:48px;
    height:48px;
    color: #fff;
    cursor:pointer;
    display:inline-block;
    padding:17px;
    line-height: 0;
    text-align:center;
}
.button svg{
    width:15px;
    height:15px;
}
.button svg path{
    fill: #fff !important;
}
#record-start{
    padding:12px;
}
#record-start svg{
    width:25px;
    height:25px;
}