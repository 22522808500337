header#login-header{
    background:none;
}
#login-content.content{
    /* background: #1F2E4D !important; */
    background: #151c46 !important;
}
.login-wrap{
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
}
.logo-wrap{
    text-align:center;    
    margin-bottom:30px;
}
.login-wrap #logo{
    margin-bottom: 20px;
    display:inline-block;
}
.login-wrap h1{
    font-size:50px;
    font-weight:600;
}
.login-wrap .login-message{
    font-size:16px;
    font-weight:400;
}
.form-login label{
    font-weight:400;
    font-size:14px;
}
.form-login .form-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
  }
.form-login .form-control{
    background: #141F34;
    color: #ffffff;
    font-size:11px;
    padding: 20px;
    border: solid 1.5px #365081;
    border-radius: 10px;
}
a.forgot-pass, a.forgot-pass:active,a.forgot-pass:focus{
    color: #F3D529;
    font-size: 11px;
    font-weight: 400;
    text-decoration: none;
    margin-left:10px;
    margin-bottom:15px;
    display:block;
    cursor: pointer;
}
a.forgot-pass:hover{
    color: #fff;
}
.btn-login{
    margin:30px auto 0;
    display:block !important;
    font-family: 'Poppins', sans-serif;
}
.btn-login svg{
    width:16px;
    height:16px;
    margin-left:30px;
}
.btn-login svg path{
    fill: #fff;
}
.btn-login:active svg path{
    fill: #000;
}