#location-content {
}
#location-content .gm-style-iw{
    /* max-width:250px !important; */
    min-width:250px !important;
}
#react-select-2-input{
    padding-bottom:12px !important;
}
.set-curr-location{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #595959;
    cursor:pointer;
}
.set-curr-location svg{
    width:21.5px;
    height:21.5px;
    position:relative;
    top:-1px;
    margin-right:7.25px;
}
.google-map-wrap{
    margin-top:35px;
    padding-bottom:100px;
}
.location-info{
    padding:20px;
}
.location-info a{
    color: #cb2800;
}
.location-info h4{
    font-size:14px;
    text-transform: uppercase;
}
.location-info .loc-address{
    color:#848a90;
    font-size:12px;
    margin-bottom:15px;
}
.location-info span{
    display:block;
    font-size:14px;
    line-height:1.6;
}
.location-info svg path{
    fill:#cb2800;
}
