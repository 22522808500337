.btn-nav {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    mix-blend-mode: normal;
    /* opacity: 0.5; */
    border: 1px solid #CED3DE;
    border-radius: 10px;
    background: #fff;
    line-height: 0;
    margin-top: 5px;
    text-align:center;
}

@media screen and (max-width:300px){
    .btn-nav{
        /* width:20px;
        height:20px; */
    }
}

.btn-ics-download{
    color: #000000;
    text-decoration: none;
    font-weight:bold;
    font-size:14px;
    border:1.5px solid #000000;
    padding: 1px 6px;
    border-radius:15px;
    margin:15px auto;
    display:block;
    width:190px;
    text-align:center;
}

.btn-ics-download:hover{
    color: #D23331;
    border-color: #D23331;
}

.btn-ics-download svg{
    margin-bottom:2px;
}

.calendar-loading {
    width: 100%;
    height: 280px;
}

.calendar-toggle {
    margin-top: 10px;
    text-align: left;
    display: block;
    cursor: pointer;
    line-height: 1;
}

.calendar-toggle.active path {
    fill: #FFBD13;
}

.btn-next {
    float: right;
}
.btn-nav svg{
    width:8px;
    height:12px;
}
.btn-nav svg path {
    stroke: #222B45;
    fill: #222B45;
}

.calendar-header {
    position: fixed;
    left: 0;
    top: 44px;
    background: #fff;
    padding-top: 11px;
    z-index: 1;
}

.page-title.month-label {
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
}

.year-label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #8F9EAC;
    display: block;
}

.platoon-selection {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
    margin-bottom: 0;
}

.platoon-selection li {
    display: inline-block;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 1.3;
    text-align: center;
    color: #FFFFFF;
    background: #ccc;
    margin-right: 7px;

    width: 26px;
    height: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.platoon-selection .platoon-a.active {
    background: #DD5762;
}

.platoon-selection .platoon-b.active {
    background: #4C74A2;
}

.platoon-selection .platoon-c.active {
    background: #15A285;
}

.platoon-selection .platoon-d.active {
    background: #F2DE4E;
}

.platoon-selection .day.active {
    background: #886C9D;
}

.platoon-selection .day {
    font-size: 9px;
    line-height: 1.7;
}

.calendar-wrap {
    display: block;
    margin-top: 40px;
}

.calendar-table {
    width: 100%;
    border-spacing: 3px;
    border-collapse: initial;
}

.calendar-table th,
.calendar-table td {
    width: calc(100% / 7);
}

.calendar-table th {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #8F9BB3;
    height: 44px;
    text-align: center;
}

.calendar-table td {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #222B45;
    border-spacing: 30px !important;
    height: 44px;
    border-radius: 5px;
    position: relative;
    padding-top: 10px;
    border: 1px solid #fff;
}

.calendar-table td.curr-date {
    cursor: pointer;
}

.calendar-table td.prev-date,
.calendar-table td.next-date {
    cursor: default;
}

.calendar-table td.today {
    background: #E6E6E6;
    border: 1px solid #E6E6E6;
}

.calendar-table td.active {
    border: 1px solid #000000;
}

.calendar-table td.holiday {
    color: rgba(255, 200, 160, 1);
    background: #FCF2E8;
}

.calendar-table td.next-date,
.calendar-table td.prev-date {
    color: #8F9BB3;
}

.date-tag-wrap {
    display: block;
    margin-top: 30px;
}

.date-tag {
    list-style-type: none;
    padding-left: 15px;
    padding-right: 15px;
}

.date-tag li {
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 6px;
    background: #ddd;
    border-radius: 5px;
    padding: 4px 15px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #FFFFFF;
}

.date-tag li.platoon-a {
    background: #DD5762;
}

.date-tag .platoon-b {
    background: #4C74A2;
}

.date-tag .platoon-c {
    background: #15A285;
}

.date-tag .platoon-d {
    background: #F2DE4E;
}

.date-tag li.holiday {
    background: rgba(255, 200, 160, 1);
}
.date-tag li.ot {
    background: #FFC800;
}
.date-tag li.ot.a {
    background: #DD5762;
}
.date-tag li.ot.b {
    background: #4C74A2;
}
.date-tag li.ot.c {
    background: #15A285;
}
.date-tag li.ot.d {
    background: #F2DE4E;
}
.date-tag li.ot.day {
    background: rgba(136, 108, 157, 1);
}
.date-tag li.dayoff {
    background: #0096FF;
}
.date-tag li.leave {
    background: #FF7B00;
}

.events-wrap {
    margin-top: 20px;
    margin-bottom: 50px;
}

.events-wrap .no-event {
    padding-left: 15px;
    display: block;
    margin-bottom: 30px;
}

.date-events {
    list-style-type: none;
    padding-left: 0;
}

.date-events li {
    padding: 5px 14px;
    margin-bottom: 14px;
}

.date-events .event-top {
    margin-bottom: 5px;
    font-size: 13px;
}

.date-events .event-top .dropdown {
    display: inline-block;
    float: right;
    width:17px;
}

.date-events .event-top .dropdown-menu {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.15));
    border-radius: 5px;
    border: none;
    font-size: 12px;
    min-width: 130px;
}

.date-events .event-top .dropdown-toggle>svg {
    width: 15px;
    height: 15px;
}

.date-events .event-top .dropdown-toggle {
    background: none;
    display: inline-block;
    padding: 0;
}

.date-events .event-top .dropdown-item {
    text-align: right;
}

.date-events .event-top .dropdown-item:last-child {
    color: #DD5762;
}

.date-events .event-top .dropdown-item:last-child svg path {
    fill: #DD5762;
    stroke: #DD5762;
}

.date-events .event-top .dropdown-item:active {
    background: #ccc;
}

.date-events .event-top .dropdown-item svg {
    width: 15px;
    height: 15px;
    margin-left: 7px;
}

.date-events .event-top svg path {
    stroke: #8F9BB3;
}

.date-events .event-time {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.75px;
    color: #8F9BB3;
    margin-left: 8px;
    margin-right:10px;
}
.date-events .media-view{
    display:inline-block;
    margin-left:0;
    padding-left:0;
    /* padding-top:10px; */
}
.date-events .media-view li{
    display:inline;
    margin:0 5px;
    padding:0;
    list-style-type:none;
}
.date-events .media-view li svg{
    width:18px;
    height:18px;
    cursor: pointer;
}

.date-events .event-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 15px;
}

.date-events .event-desc {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.75px;
    color: #8F9BB3;
    overflow-wrap: break-word;
}

.date-events .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 10px;
    border: 3px solid #000;
    margin-right: 5px;
}

.date-events .holiday .circle {
    border-color: rgba(255, 200, 160, 1);
}

.date-events li:active .holiday .circle {
    border-color: rgba(255, 255, 255, 1);
}

.date-events .note .circle {
    border-color: rgba(115, 91, 242, 1);
}

.date-events li:active .note .circle {
    border-color: rgba(255, 255, 255, 1);
}

.date-events .event .circle {
    border-color: rgba(0, 179, 131, 1);
}

.date-events li:active .event .circle {
    border-color: rgba(255, 255, 255, 1);
}

.date-events .annual .circle {
    border-color: rgba(255, 122, 0, 1);
}

.date-events li:active .annual .circle {
    border-color: rgba(255, 255, 255, 1);
}

.date-events .dayoff .circle {
    border-color: rgba(0, 149, 255, 1);
}

.date-events li:active .dayoff .circle {
    border-color: rgba(255, 255, 255, 1);
}

.date-events .training .circle {
    border-color: rgba(244, 89, 138, 1);
}

.date-events li:active .training .circle {
    border-color: rgba(255, 255, 255, 1);
}

.date-events .ot .circle {
    border-color: rgba(255, 199, 0, 1);
}

.date-events li:active .ot .circle {
    border-color: rgba(255, 255, 255, 1);
}

.date-events .bg{
    border-radius: 11px;
    background: rgba(100,100,100,0.3);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    padding: 5px 20px 5px 10px;
    text-decoration: none;
    /* margin-left:-10px; */
}
.date-events .holiday .bg{
    background: rgba(255, 200, 160, 0.1);
}
.date-events .note .bg{
    background: rgba(115, 91, 242, 0.1);
}
.date-events .event .bg{
    background: rgba(0, 179, 131, 0.1);
}
.date-events .annual .bg{
    background: rgba(255, 122, 0, 0.1)
}
.date-events .dayoff .bg{
    background: rgba(0, 149, 255, 0.1);
}
.date-events .training .bg{
    background: rgba(244, 89, 138, 0.1);
}
.date-events .ot .bg{
    background: rgba(255, 199, 0, 0.1);
}
.event-content{
    padding-top:15px;
    padding-left:11px;
}
.platoon-legend {
    display: flex;
    position: absolute;
    top: 3px;
    width: 100%;
    justify-content: center;
    gap: 2px;
}

.platoon-legend>span {
    height: 13px;
    width: 50%;
    background: #ddd;
    border-radius: 2px;
    line-height: 0.7;
}

.platoon-legend>span svg {
    line-height: 1;
}

.platoon-legend .platoon-a {
    background: #DD5762;
}

.platoon-legend .platoon-b {
    background: #4C74A2;
}

.platoon-legend .platoon-c {
    background: #15A285;
}

.platoon-legend .platoon-d {
    background: #F2DE4E;
}

.event-legend {
    display: flex;
    position: absolute;
    bottom: 3px;
    width: 100%;
    justify-content: center;
    gap: 2px;
}

.event-legend>span {
    height: 4px;
    width: 50%;
    background: #ddd;
    border-radius: 2px;
}

.event-legend .event {
    background: #00B383;
}

.event-legend .note {
    background: #7C65F3;
}

.event-legend .ot.a {
    background: #DD5762;
}
.event-legend .ot.b {
    background: #4C74A2;
}
.event-legend .ot.c {
    background: #15A285;
}
.event-legend .ot.d {
    background: #F2DE4E;
}
.event-legend .ot.day {
    background: rgba(136, 108, 157, 1);
}
.holiday-state{
    padding-top:10px;
    padding-left:10px;
    border:none;
    font-size:10px;
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    font-weight:bold;
    display:block;
    background: none;
    line-height: 1;
}    
.holiday-state::-ms-expand{
    display: none;
}