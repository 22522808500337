.view-note-overlay{
    z-index: 1000 !important;
}
.view-note-content {
    padding: 50px 20px 50px 20px !important;
}

.list-note {
    /* position: */
}

.list-note .dropdown {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #8F9BB3;
}

.list-note .dropdown-toggle {
    background: none !important;
    display: inline-block;
    padding: 0;
}

.list-note .dropdown-toggle>svg {
    width: 15px;
    height: 15px;
}

.list-note .dropdown-toggle>svg path {
    fill: #8F9BB3;
}

.list-note .dropdown-menu {
    filter: drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.15));
    border-radius: 5px;
    border: none;
    font-size: 12px;
    min-width: 130px;
}

.list-note .dropdown-item {
    text-align: right;
}

.list-note .dropdown-item svg {
    width: 15px;
    height: 15px;
    margin-left: 7px;
}

.list-note .dropdown-item:last-child {
    color: #DD5762;
}

.list-note .dropdown-item:last-child svg path {
    fill: #DD5762;
    stroke: #DD5762;
}

.list-note .dropdown-toggle::after {
    display: none;
}

.list-note .item-header{
    max-width: 160px;
    display: inline-block;
}

.list-note .media-view{
    display:inline-block;
    margin-left:0;
    padding-left:0;
    padding-right:0;
    margin-bottom:0;
    float:right;
    margin-right:20px;
    /* padding-top:10px; */
}
.list-note .media-view li{
    display:inline;
    margin:0 5px;
    padding:0;
    list-style-type:none;    
    border:none;
}
.list-note .media-view li svg{
    width:18px;
    height:18px;
    cursor: pointer;
    border:none;
}
.view-note-content .media-view{
    display:inline-block;
    margin-left:0;
    padding-left:0;
    /* padding-top:10px; */
    position:absolute;
    bottom:0;
    right:15px;
}
.view-note-content .media-view li{
    display:inline;
    margin:0 5px;
    padding:0;
    list-style-type:none;
}
.view-note-content .media-view li svg{
    width:18px;
    height:18px;
    cursor: pointer;
}